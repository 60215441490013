import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { distinctUntilChanged, forkJoin } from 'rxjs';
import { ModalErrorGeneralComponent } from '../../../common/modal-error-general/modal-error-general.component';
import { ServiceFormAnamnesisService } from '../../../../shared/services/service-form-anamnesis.service';
import { IDataEspecialidades } from '../../../../shared/interfaces/especialidades.interface';
import { IDataAlegias } from '../../../../shared/interfaces/alergias.interface';
import { IDataEnfermedades } from '../../../../shared/interfaces/enfermedades.interface';
import { IDataCirugias } from '../../../../shared/interfaces/cirugias.interface';
import { IDataMedicamento } from '../../../../shared/interfaces/tratamiento/medicamentos.interface';
import { AnamnesisService } from '../../../services/anamnesis.service';
import { IDataAnamnesis } from '../../../../interfaces/anamnesis.interface';
import { InputWithChipFormFieldV2Component } from '../../../../shared/components/input-with-chip-form-field-v2/input-with-chip-form-field-v2.component';
import {
  SnackInfoComponent,
  SnackInfoProps,
  SnackInfoType,
} from '../../../../shared/components/snack-info/snack-info.component';
import { TextareaWithSpeechToTextFormFieldComponent } from '../../../../shared/components/textarea-with-speech-to-text-form-field/textarea-with-speech-to-text-form-field.component';
import { SelectSearchFormFieldComponent } from '../../../../shared/components/select-search-form-field/select-search-form-field.component';
import { FichaClinicaStore } from '../../../../shared/stores/ficha-clinica/ficha-clinica.store';
import { isFinalizedStatus } from '../../../../shared/helpers/ficha-clinica.helper';

interface AnamnesisForm {
  temperatura: FormControl<string | null>;
  saturacion: FormControl<string | null>;
  frecuenciaCardiaca: FormControl<string | null>;
  presionArterial: FormControl<string | null>;

  alergias: FormControl<Array<string> | null>;
  enfermedades: FormControl<Array<string> | null>;
  cirugias: FormControl<Array<string> | null>;
  medicamentos: FormControl<Array<string> | null>;

  especialidad: FormControl<string | null>;

  antecedentes: FormControl<string | null>;
  relato: FormControl<string | null>;
  estiloVida: FormControl<string | null>;
}

@Component({
  selector: 'soph-anamnesis',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatDividerModule,
    MatExpansionModule,
    MaskitoDirective,
    MatChipsModule,
    MatAutocompleteModule,
    SelectSearchFormFieldComponent,
    InputWithChipFormFieldV2Component,
    TextareaWithSpeechToTextFormFieldComponent,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './anamnesis.component.html',
  styleUrl: './anamnesis.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnamnesisComponent {
  readonly path = 'anamnesis';
  readonly dialog = inject(MatDialog);
  readonly snackBar = inject(MatSnackBar);

  public specialities = signal<IDataEspecialidades[]>([]);
  public allergies = signal<IDataAlegias[]>([]);
  public illnesses = signal<IDataEnfermedades[]>([]);
  public surgeries = signal<IDataCirugias[]>([]);
  public medicines = signal<IDataMedicamento[]>([]);

  private readonly anamnesis = signal<IDataAnamnesis | null>(null);
  public readonly hasChanges = signal<boolean>(false);

  readonly inputTemperaturaMask: MaskitoOptions = {
    mask: /^\d{0,3}(\.\d{0,2})?$/,
    overwriteMode: 'shift',
    postprocessors: [
      ({ value, selection }) => {
        if (!value) {
          return {
            value: '',
            selection: [0, 0],
          };
        }

        const newValue = `${value} °C`;
        return {
          value: newValue,
          selection: [newValue.length - 3, newValue.length - 3],
        };
      },
    ],
  };

  readonly inputSaturacionMask: MaskitoOptions = {
    mask: /^\d{0,3}$/,
    overwriteMode: 'shift',
    postprocessors: [
      ({ value, selection }) => {
        if (!value) {
          return {
            value: '',
            selection: [0, 0],
          };
        }

        const newValue = `${value} %`;
        return {
          value: newValue,
          selection: [newValue.length - 2, newValue.length - 2],
        };
      },
    ],
  };

  readonly inputFrecuenciaCardiacaMask: MaskitoOptions = {
    mask: /^\d{0,3}$/,
    overwriteMode: 'shift',
    postprocessors: [
      ({ value, selection }) => {
        if (!value) {
          return {
            value: '',
            selection: [0, 0],
          };
        }

        const newValue = `${value} ppm`;
        return {
          value: newValue,
          selection: [newValue.length - 4, newValue.length - 4],
        };
      },
    ],
  };

  readonly inputPresionArterialMask: MaskitoOptions = {
    mask: /^\d{0,3}\/?\d{0,3}$/,
    overwriteMode: 'shift',
    postprocessors: [
      ({ value, selection }) => {
        const cleanedValue = value
          .replace(/ mmHg$/, '')
          .replace('/', '')
          .trim();

        const systolic = cleanedValue.slice(0, 3);
        const diastolic = cleanedValue.slice(3, 6);

        let finalValue = systolic;
        if (diastolic) {
          finalValue += `/${diastolic}`;
        }

        const newValue = finalValue ? `${finalValue} mmHg` : '';

        return {
          value: newValue,
          selection: [
            newValue.length > 3 ? newValue.length - 5 : newValue.length,
            newValue.length > 3 ? newValue.length - 5 : newValue.length,
          ],
        };
      },
    ],
  };

  public form: FormGroup<AnamnesisForm>;
  readonly errorMessage: WritableSignal<{ [key: string]: string }> = signal({});

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _anamnesisDataService: ServiceFormAnamnesisService,
    private readonly _anamnesisService: AnamnesisService,
    private readonly _fichaClinicaStore: FichaClinicaStore
  ) {
    this.form = this._fb.group({
      temperatura: new FormControl<string | null>(null, Validators.compose([])),
      saturacion: new FormControl<string | null>(null, Validators.compose([])),
      frecuenciaCardiaca: new FormControl<string | null>(
        null,
        Validators.compose([])
      ),
      presionArterial: new FormControl<string | null>(
        null,
        Validators.compose([])
      ),
      alergias: new FormControl<Array<string> | null>(
        null,
        Validators.compose([Validators.required])
      ),
      enfermedades: new FormControl<Array<string> | null>(
        null,
        Validators.compose([Validators.required])
      ),
      cirugias: new FormControl<Array<string> | null>(
        null,
        Validators.compose([Validators.required])
      ),
      medicamentos: new FormControl<Array<string> | null>(
        null,
        Validators.compose([Validators.required])
      ),
      especialidad: new FormControl<string | null>(
        null,
        Validators.compose([])
      ),
      antecedentes: new FormControl<string | null>(
        null,
        Validators.compose([Validators.maxLength(1000)])
      ),
      relato: new FormControl<string | null>(
        null,
        Validators.compose([Validators.required, Validators.maxLength(1000)])
      ),
      estiloVida: new FormControl<string | null>(
        null,
        Validators.compose([Validators.maxLength(1000)])
      ),
    });

    this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((newValues) => {
        this.hasChanges.set(this.checkForChanges(newValues));
      });

    this._fichaClinicaStore.state$
      .pipe(distinctUntilChanged())
      .subscribe((values) => {
        this.toggleFormState(isFinalizedStatus(values.estado || ''));
      });
  }

  ngOnInit() {
    this.getData();
  }

  private toggleFormState(shouldDisable: boolean) {
    shouldDisable ? this.form.disable() : this.form.enable();
  }

  public updateErrorMessage(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    const currentErrorMessages = this.errorMessage();

    if (control?.hasError('required')) {
      currentErrorMessages[fieldName] = 'Este campo es obligatorio';
    } else {
      currentErrorMessages[fieldName] = '';
    }

    this.errorMessage.set({ ...currentErrorMessages });
  }

  public isRequired(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.hasError('required') ?? false;
  }

  public isInvalid(fieldName: string) {
    const control: AbstractControl | null = this.form.get(fieldName);
    return control?.invalid ?? false;
  }

  public revertChanges() {
    if (this.anamnesis()) {
      const {
        signosVitales: {
          temperatura,
          saturacion,
          frecuenciaCardiaca,
          presionArterial,
        },
        ...data
      } = this.anamnesis()!;

      this.form.patchValue({
        ...data,
        temperatura,
        saturacion,
        frecuenciaCardiaca,
        presionArterial,
      });
    } else {
      this.form.reset();
    }
  }

  private checkForChanges(newValues: any): boolean {
    if (this.anamnesis()) {
      const {
        signosVitales: {
          temperatura,
          saturacion,
          frecuenciaCardiaca,
          presionArterial,
        },
        ...data
      } = this.anamnesis()!;

      const originalData = {
        temperatura,
        saturacion,
        frecuenciaCardiaca,
        presionArterial,
        ...data,
      };

      return JSON.stringify(newValues) !== JSON.stringify(originalData);
    } else {
      const defaultValue = {
        temperatura: null,
        saturacion: null,
        frecuenciaCardiaca: null,
        presionArterial: null,
        alergias: null,
        enfermedades: null,
        cirugias: null,
        medicamentos: null,
        especialidad: null,
        antecedentes: null,
        relato: null,
        estiloVida: null,
      };

      return JSON.stringify(defaultValue) !== JSON.stringify(newValues);
    }
  }

  public onSave(form: FormGroup) {
    if (form.valid) {
      const { value } = form;

      this._anamnesisService
        .guardar({
          ...value,
          signosVitales: [
            value.temperatura,
            value.saturacion,
            value.frecuenciaCardiaca,
            value.presionArterial,
          ].filter(
            (vital) => vital !== undefined && vital !== null && vital !== ''
          ),
          idAgendamiento: this._fichaClinicaStore.values.idAgendamiento,
        })
        .subscribe({
          next: () => {
            this.anamnesis.set({
              signosVitales: {
                temperatura: value.temperatura,
                saturacion: value.saturacion,
                frecuenciaCardiaca: value.frecuenciaCardiaca,
                presionArterial: value.presionArterial,
              },
              ...value,
            });

            this.form.updateValueAndValidity();

            this._fichaClinicaStore.updateMedicalHistoryBySection(
              'anamnesis',
              true
            );

            this.snackBar.openFromComponent(SnackInfoComponent, {
              data: {
                message: 'Los cambios han sido guardado con éxito',
                type: SnackInfoType.SUCCESS,
              } as SnackInfoProps,
            });
          },
          error: () => {
            this.snackBar.openFromComponent(SnackInfoComponent, {
              data: {
                message:
                  'No pudimos guardar los cambios, por favor intenta nuevamente',
                type: SnackInfoType.ERROR,
              } as SnackInfoProps,
            });
          },
        });
    }
  }

  private getData() {
    forkJoin([
      this._anamnesisDataService.getEspercialidades(),
      this._anamnesisDataService.getAlergias(),
      this._anamnesisDataService.getEnfermedades(),
      this._anamnesisDataService.getCirugias(),
      this._anamnesisDataService.getMedicamentos(),
    ]).subscribe({
      next: ([
        especialidadResponse,
        alergiasResponse,
        enfermedadesResponse,
        cirugiasResponse,
        medicamentosResponse,
      ]) => {
        this.specialities.set(especialidadResponse.data);
        this.allergies.set(alergiasResponse.data);
        this.illnesses.set(enfermedadesResponse.data);
        this.surgeries.set(cirugiasResponse.data);
        this.medicines.set(medicamentosResponse.data);

        if (this._fichaClinicaStore.values.idAgendamiento) {
          this.getAnamnesis();
        }
      },
      error: () => {
        this.dialog.open(ModalErrorGeneralComponent);
      },
    });
  }

  private getAnamnesis() {
    this._anamnesisService
      .obtener(this._fichaClinicaStore.values.idAgendamiento!)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.form.reset();

            const {
              signosVitales: {
                temperatura,
                saturacion,
                frecuenciaCardiaca,
                presionArterial,
              },
              ...data
            } = response.data;

            this.anamnesis.set(response.data);

            this.form.patchValue({
              ...data,
              temperatura,
              saturacion,
              frecuenciaCardiaca,
              presionArterial,
            });
          }
        },
        error: () => {
          this.dialog.open(ModalErrorGeneralComponent);
        },
      });
  }
}
