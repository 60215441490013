export const environment = {
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyAHSpKRfLnsgJFlCJmjKDizyUke1d3Gi-E",
      authDomain: "sza-preprod.firebaseapp.com",
      projectId: "sza-preprod",
      storageBucket: "sza-preprod.appspot.com",
      messagingSenderId: "359616739836",
      appId: "1:359616739836:web:a4649c99522fe15a221a79",
      measurementId: "G-ZV3JBBJH54"
    },
    tenantId: "dev-sophia-5s573",
    baseURL: "https://dev.api.sophia.seiza-ti.cl/api/v1/"
};
