<form [formGroup]="form" (ngSubmit)="onSave(form)" autocomplete="off">
  <div class="container">
    <!-- Signos Vitales -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel"
              >Signos vitales</mat-panel-title
            >
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-half">
              <p class="texto-info">Temperatura (°C)</p>
              <p>Ingrese la temperatura del paciente en grados Celsius</p>
              <mat-form-field
                class="estilo-input"
                appearance="outline"
                subscriptSizing="dynamic"
              >
                <mat-label>Temperatura</mat-label>
                <input
                  matInput
                  placeholder="Temperatura"
                  formControlName="temperatura"
                  [maskito]="inputTemperaturaMask"
                  (blur)="updateErrorMessage('temperatura')"
                  [required]="isRequired('temperatura')"
                />
                @if (isInvalid('temperatura') && errorMessage()['temperatura'])
                {
                <mat-error>{{ errorMessage()["temperatura"] }}</mat-error>
                }
              </mat-form-field>
            </div>
            <div class="input-half">
              <p class="texto-info">Saturación (%)</p>
              <p>Ingrese la saturación del paciente</p>
              <mat-form-field
                class="estilo-input"
                appearance="outline"
                subscriptSizing="dynamic"
              >
                <mat-label>Saturación</mat-label>
                <input
                  matInput
                  formControlName="saturacion"
                  placeholder="Saturación"
                  [maskito]="inputSaturacionMask"
                  (blur)="updateErrorMessage('saturacion')"
                  [required]="isRequired('saturacion')"
                />
                @if (isInvalid('saturacion') && errorMessage()['saturacion']) {
                <mat-error>{{ errorMessage()["saturacion"] }}</mat-error>
                }
              </mat-form-field>
            </div>
          </div>

          <div class="input-container">
            <div class="input-half">
              <p class="texto-info">Frecuencia cardíaca (ppm)</p>
              <p>Ingresa la frecuencia cardíaca del paciente</p>
              <mat-form-field
                class="estilo-input"
                appearance="outline"
                subscriptSizing="dynamic"
              >
                <mat-label>Frecuencia cardíaca</mat-label>
                <input
                  matInput
                  formControlName="frecuenciaCardiaca"
                  placeholder="Frecuencia cardíaca"
                  [maskito]="inputFrecuenciaCardiacaMask"
                  (blur)="updateErrorMessage('frecuenciaCardiaca')"
                  [required]="isRequired('frecuenciaCardiaca')"
                />
                @if (isInvalid('frecuenciaCardiaca') &&
                errorMessage()['frecuenciaCardiaca']) {
                <mat-error>{{
                  errorMessage()["frecuenciaCardiaca"]
                }}</mat-error>
                }
              </mat-form-field>
            </div>
            <div class="input-half">
              <p class="texto-info">Presión arterial (mmHg)</p>
              <p>Ingresar presión arterial del paciente</p>
              <mat-form-field
                class="estilo-input"
                appearance="outline"
                subscriptSizing="dynamic"
              >
                <mat-label>Presión arterial</mat-label>
                <input
                  matInput
                  formControlName="presionArterial"
                  placeholder="Presión arterial"
                  [maskito]="inputPresionArterialMask"
                  (blur)="updateErrorMessage('presionArterial')"
                  [required]="isRequired('presionArterial')"
                />
                @if (isInvalid('presionArterial') &&
                errorMessage()['presionArterial']) {
                <mat-error>{{ errorMessage()["presionArterial"] }}</mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Historial de alergias, enfermedades pre-existentes y tratamientos -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel">
              Historial de alergias, enfermedades pre-existentes y tratamientos
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-half">
              <p class="texto-info">Alergias</p>
              <p>Selecciona las alergias que indica el paciente</p>
              <soph-input-with-chip-form-field-v2
                [formGroup]="form"
                controlName="alergias"
                label="Alergias"
                placeholder="Seleccionar alergias"
                [values]="allergies"
                idField="idAlergia"
                viewField="nombre"
              ></soph-input-with-chip-form-field-v2>
            </div>

            <div class="input-half">
              <p class="texto-info">Enfermedades pre-existentes</p>
              <p>Selecciona las enfermedades que indica el paciente</p>
              <soph-input-with-chip-form-field-v2
                [formGroup]="form"
                controlName="enfermedades"
                label="Enfermedades pre-existentes"
                placeholder="Seleccionar enfermedades"
                [values]="illnesses"
                idField="idEnfermedad"
                viewField="nombre"
              ></soph-input-with-chip-form-field-v2>
            </div>
          </div>

          <div class="input-container">
            <div class="input-half">
              <p class="texto-info">Cirugías anteriores</p>
              <p>Selecciona las cirugías que indica el paciente</p>
              <soph-input-with-chip-form-field-v2
                [formGroup]="form"
                controlName="cirugias"
                label="Cirugías anteriores"
                placeholder="Seleccionar las cirugías que indica el paciente"
                [values]="surgeries"
                idField="idCirugia"
                viewField="nombre"
              ></soph-input-with-chip-form-field-v2>
            </div>

            <div class="input-half">
              <p class="texto-info">Medicamentos de uso frecuente</p>
              <p>Selecciona los medicamentos que indica el paciente</p>
              <soph-input-with-chip-form-field-v2
                [formGroup]="form"
                controlName="medicamentos"
                label="Medicamentos de uso frecuente"
                placeholder="Seleccionar medicamentos"
                [values]="medicines"
                idField="idMedicamento"
                viewField="nombre"
              ></soph-input-with-chip-form-field-v2>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Referido -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel">
              Referido
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-total">
              <p>
                Selecciona la especialidad del especialista que refirio al
                paciente
              </p>
              <soph-select-search-form-field
                [formGroup]="form"
                controlName="especialidad"
                [values]="specialities"
                label="Seleccionar la especialidad"
                placeholder="Seleccionar la especialidad"
                idField="idEspecialidad"
                viewField="nombre"
              >
              </soph-select-search-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Antecedentes familiares -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel">
              Antecedentes familiares
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-total">
              <p>Ingresa los antecedentes familiares que sean relevantes</p>
              <soph-textarea-with-speech-to-text-form-field
                [control]="form.controls['antecedentes']"
                label="Antecedentes familiares"
                placeholder="Comentarios"
                [maxLength]="1000"
                [errorMessages]="{
                  required: 'Este campo es obligatorio',
                  maxlength: 'Ha excedido el número máximo de caracteres'
                }"
              >
              </soph-textarea-with-speech-to-text-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Relato -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel"
              >Relato</mat-panel-title
            >
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-total">
              <p>Ingresa el motivo de visita del paciente</p>
              <soph-textarea-with-speech-to-text-form-field
                [control]="form.controls['relato']"
                label="Relato"
                placeholder="Relato"
                [maxLength]="1000"
                [errorMessages]="{
                  required: 'Este campo es obligatorio',
                  maxlength: 'Ha excedido el número máximo de caracteres'
                }"
              >
              </soph-textarea-with-speech-to-text-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Estilo de vida -->
    <div class="full-width">
      <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="texto-info text-panel"
              >Estilo de vida</mat-panel-title
            >
          </mat-expansion-panel-header>

          <div class="input-container">
            <div class="input-total">
              <p>
                Ingresa el estilo de vida, por ejemplo horas de sueño, hacer
                deporte, fumar, beber alcohol, actividad sexual, otras.
              </p>
              <soph-textarea-with-speech-to-text-form-field
                [control]="form.controls['estiloVida']"
                label="Estilo de vida"
                placeholder="Estilo de vida"
                [maxLength]="1000"
                [errorMessages]="{
                  required: 'Este campo es obligatorio',
                  maxlength: 'Ha excedido el número máximo de caracteres'
                }"
              >
              </soph-textarea-with-speech-to-text-form-field>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Acciones -->
    <div class="section-action">
      <mat-divider></mat-divider>
      <div class="section-action__buttons">
        <button
          mat-stroked-button
          class="estilo-button"
          type="button"
          (click)="revertChanges()"
          [disabled]="!hasChanges()"
        >
          Cancelar
        </button>
        <button mat-flat-button type="submit" [disabled]="!hasChanges()">
          Guardar
        </button>
      </div>
    </div>
  </div>
</form>
